import * as React from "react";
import HcpLayout from "../components/layout";
import { Seo } from "../components/seo";
import "./our-approach.scss";
import FluidContent from "../components/fluidContent";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import { Col, Row } from "react-grid-system";
import CardInfo from "../components/cards/cardInfo";
import ListBg1 from "../images/about-item1-bg.webp";
import ListBg2 from "../images/bg-water-skyblue.webp";

import ListBg3 from "../images/bg-water-orange.webp";
import ListBg4 from "../images/bg-water-softblue.webp";
import graphicLogo from "../images/graphic-logomark.svg";
import graphicLogoMobile from "../images/graphic-logomark-mobile.svg";
import approachDnaDesktop from "../images/image-approach-dna-desktop.jpg";
import BlockTitle from "../components/BlockTitle";
import InnerBanner from "../components/innerBanner";
import ScrollTrigger from "gsap/ScrollTrigger";
import { gsap } from "gsap";
import Button from "../components/Button";
import useDeviceSize from "../hooks/use-device-size";
import MyLink from "../components/MyLink";
import agileraLogo from "../images/partner-logo-agilera.webp";
import alphaGenLogo from "../images/partner-logo-alphagen.webp";
import eckertandzieglerLogo from "../images/partner-logo-eckertandziegler.webp";

import nucleusLogo from "../images/logo-nucleus-radiopharma-retina.png";
import pharmalogicLogo from "../images/partner-logo-pharmalogic.webp";
import spectronrxLogo from "../images/partner-logo-spectronrx.webp";
//import fogLogo from "../images/logo-fogpharma.webp";
import parabilismedicines from "../images/partner-logo-parabilismedicines.webp";

const OurApproachPage = () => {
  const fadeRef = React.useRef([]);
  const deviceSize = useDeviceSize();
  fadeRef.current = [];

  const fadeToRefs = (fade) => {
    if (fade) {
      fadeRef.current.push(fade);
    }
  };

  React.useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    fadeRef.current.forEach((fade) => {
      gsap.set(fade, {
        opacity: 0,
        y: 100,
      });

      gsap.to(fade, {
        opacity: 1,
        y: 0,
        duration: 1.5,
        scrollTrigger: {
          trigger: fade,
        },
      });
    });
  }, []);

  return (
    <HcpLayout pageClass="our-approach-page">
      <div className="hero-wrapper">
        <InnerBanner />
      </div>
      <FluidContent className="therapies-block bg-cover block-inner-py">
        <img
          src={approachDnaDesktop}
          alt="DNA Shape"
          className="desktop-only"
          loading="lazy"
        />
        <div className="centered-content text-white" ref={fadeToRefs}>
          <BlockTitle
            className="gradient-border gradient-border--yellow text-white"
            Title="Radioligand Therapies and the Potential of ART"
          />
          <div className="therapies-block__content">
            <p>
              Cancer treatment has improved dramatically; however, there remains
              an enormous need for more therapies that offer greater precision
              and efficacy against tumors and deliver better safety and
              tolerability for patients. Radioligand therapy (RLT) is one of the
              most promising technologies which can address these unmet needs.
              In fact, recently approved RLTs have already significantly
              improved patient survival and quality of life.{" "}
            </p>
            <p>
              The RLT landscape is currently dominated by beta-emitting
              isotopes, which sacrifice precision and efficacy in favor of
              centralized manufacturing and distribution. In contrast, alpha
              emitters used in ARTs have the potential to deliver significantly
              better efficacy to patients. Alpha radiation can result in greater
              damage to the cancer tissue by causing double stranded DNA breaks
              in cancer cells with less penetration in healthy tissue.
            </p>
            <p>
              ARTBIO’s unique approach harnesses the power of ARTs and overcomes
              the challenges of existing RLTs.
            </p>
          </div>
        </div>
      </FluidContent>

      <FluidContent className="graphic-block">
        <div className="centered-content">
          <div className="graphic" ref={fadeToRefs}>
            <div className="media">
              <img
                src={graphicLogo}
                alt="Graphic Logo mark"
                className="graphic-logo desktop-only"
                loading="lazy"
              />
              <img
                src={graphicLogoMobile}
                alt="Graphic Logo mark"
                className="graphic-logo mobile-only mb-30"
                loading="lazy"
              />
            </div>
            <div className="title mb-30 mobile-only">
              <h2>Patients at the Core</h2>
            </div>
            <Row className="row">
              <Col lg={4.5}>
                <Row>
                  <Col sm={12} className="mb-36">
                    <MyLink to="/our-approach#accordion-wrap">
                      <CardInfo
                        className="bradius-topleft-40 left-justify"
                        cardBg={ListBg1}
                        cardBgAlt="bg-red shape"
                        cardTitle="Differentiated pipeline"
                      />
                    </MyLink>
                  </Col>
                  <Col sm={12} className="mb-36">
                    <MyLink to="/our-approach#accordion-wrap">
                      <CardInfo
                        className="bradius-bottomleft-40 left-justify"
                        cardBg={ListBg3}
                        cardBgAlt="bg-orange shape"
                        cardTitle="Ideal isotope: Pb212"
                      />
                    </MyLink>
                  </Col>
                </Row>
              </Col>
              <Col lg={3} className="desktop-only">
                <div className="title">
                  <h2>Patients at the Core</h2>
                </div>
              </Col>
              <Col lg={4.5}>
                <Row>
                  <Col sm={12} className="mb-36">
                    <MyLink to="/our-approach#accordion-wrap">
                      <CardInfo
                        className="bradius-topright-40 right-justify"
                        cardBg={ListBg2}
                        cardBgAlt="bg-sky-blue shape"
                        cardSupTitle={`<span> Isotope production: <i>AlphaDirect<sup>TM</sup></i></span>`}
                      />
                    </MyLink>
                  </Col>
                  <Col sm={12} className="mb-36 ">
                    <MyLink to="/our-approach#accordion-wrap">
                      <CardInfo
                        className="bradius-bottomright-40 right-justify pl-40"
                        cardBg={ListBg4}
                        cardBgAlt="bg-voilet shape"
                        cardTitle="ART manufacturing ecosystem"
                      />
                    </MyLink>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </FluidContent>

      <div id="accordion-wrap">
        <FluidContent className="accordion-block">
          <div className="centered-content">
            <h3>
              We keep the needs of patients at our core – to effectively fight
              tumors. The ARTBIO approach rests upon four pillars:
            </h3>
            <Accordion
              allowZeroExpanded
              className="custom-accordion"
              allowMultipleExpanded={deviceSize.mdDown}
            >
              <AccordionItem>
                <Row>
                  <Col lg={9}>
                    <AccordionItemHeading className="gradient-border gradient-border--red">
                      <AccordionItemButton>
                        A differentiated pipeline of ARTs focused on the
                        greatest unmet medical needs
                      </AccordionItemButton>
                    </AccordionItemHeading>
                  </Col>
                </Row>
                <Row>
                  <Col lg={9}>
                    <AccordionItemPanel>
                      <p className="p-btm-30">
                        Our holistic approach finds the combinations of target
                        and drug molecule for ART that have the greatest chance
                        of delivering improvements to patients. Our approach
                        allows us to maximize the potential of ART and
                        ultimately positively improve patient outcomes.
                      </p>
                      <Row className="row-second">
                        <Col lg={10} md={12}>
                          <p className="m-btm-0">
                            We work closely with discovery and development
                            partners to advance next-generation
                            radiopharmaceuticals specifically designed to
                            improve patient outcomes.
                          </p>
                        </Col>
                        <Col lg={2} md={12}>
                          <img
                            src={parabilismedicines}
                            alt="Parabilis Medicines logo"
                            className="mw-124"
                            width={124}
                            height={84}
                            loading="lazy"
                          />
                        </Col>
                      </Row>
                      <div className="btn-wrap mt-30">
                        <MyLink to="/#pipeline">
                          <Button btnTitle="View Our Pipeline" />
                        </MyLink>
                      </div>
                    </AccordionItemPanel>
                  </Col>
                </Row>
              </AccordionItem>
              <AccordionItem>
                <Row>
                  <Col lg={9}>
                    <AccordionItemHeading className="gradient-border gradient-border--yellow">
                      <AccordionItemButton>
                        The alpha emitter with the ideal clinical profile: Pb212
                      </AccordionItemButton>
                    </AccordionItemHeading>
                  </Col>
                </Row>
                <Row>
                  <Col lg={9}>
                    <AccordionItemPanel>
                      <p>
                        Our ARTs use Pb212 because it can quickly deliver
                        radiation to tumors that are most likely to respond.
                        Pb212 is the ideal isotope because it:
                      </p>
                      <div className="dot-list">
                        <ul>
                          <li>
                            Is short lived and matches the properties of its
                            small molecule carriers, thereby delivering most of
                            its energy into the tumor, quickly.
                          </li>
                          <li>
                            Has high stability in its carrier molecule, with
                            potential positive impact on safety and efficacy.
                          </li>
                          <li>
                            Can be imaged in SPECT/CT leading to refined
                            clinical efficacy assessments.
                          </li>
                        </ul>
                      </div>
                    </AccordionItemPanel>
                  </Col>
                </Row>
              </AccordionItem>
              <AccordionItem>
                <Row>
                  <Col lg={9}>
                    <AccordionItemHeading className="gradient-border gradient-border--lightblue">
                      <AccordionItemButton>
                        Proprietary Pb212 isolation technology:{" "}
                        <i>
                          AlphaDirect<sup>TM</sup>
                        </i>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                  </Col>
                </Row>
                <Row>
                  <Col lg={9}>
                    <AccordionItemPanel>
                      <p>
                        In contrast to other ARTs in which the alpha isotopes
                        are made in nuclear reactors or high energy cyclotrons
                        with rigid schedules in large, centralized facilities,
                        we are pioneering an optimized and proprietary isotope
                        isolation technology.
                      </p>
                      <p>
                        Our patented{" "}
                        <i>
                          AlphaDirect<sup>TM</sup>
                        </i>{" "}
                        technology has unique advantages that make it a
                        first-of-its-kind system to effectively isolate Pb212:
                      </p>
                      <div className="dot-list">
                        <ul>
                          <li>
                            {" "}
                            Benchtop format, which can be operated in many
                            different facilities that assemble therapeutic
                            product.
                          </li>
                          <li>
                            {" "}
                            Simple process with no separation that results in
                            99.9%+ purity.
                          </li>
                          <li>
                            Streamlined supply chain with starting materials
                            derived from accessible nuclear industry stockpiles
                            that minimize uncertainty and cost.
                          </li>
                        </ul>
                      </div>
                    </AccordionItemPanel>
                  </Col>
                </Row>
              </AccordionItem>
              <AccordionItem>
                <Row>
                  <Col lg={9}>
                    <AccordionItemHeading className="gradient-border gradient-border--blue">
                      <AccordionItemButton>
                        A distributed ART manufacturing ecosystem to make our
                        ARTs rapidly accessible
                      </AccordionItemButton>
                    </AccordionItemHeading>
                  </Col>
                </Row>
                <Row>
                  <Col lg={9}>
                    <AccordionItemPanel>
                      <p>
                        We are building a unique ART ecosystem to adequately
                        deliver our therapies to as many eligible patients as
                        possible. Our ecosystem acts as an end-to-end
                        distributed manufacturing network with reliable, fast,
                        and flexible delivery of our therapeutic candidates to
                        the bedside.
                      </p>
                      <p>
                        Our Distributed Manufacturing approach is radically
                        different from current pharmaceutical industry
                        standards:
                      </p>

                      <div className="dot-list">
                        <ul>
                          <li>
                            {" "}
                            Our infrastructure matches the dynamics of
                            short-lived Pb212 with adequate redundancies and
                            flexibility.
                          </li>
                          <li>
                            {" "}
                            Our strategic partnerships will help us maximize
                            patient access and mitigate the shortcomings of
                            centralized manufacturing approaches which, to date,
                            have been unreliable in delivering therapies to
                            eligible patients in need.
                          </li>
                        </ul>
                      </div>
                      <Row
                        className="row accordion-logo__wrapper"
                        justify="center"
                      >
                        <Col lg={4} md={6} className="col">
                          <div className="partners-logo text-lg-left">
                            <img
                              src={agileraLogo}
                              alt="Agilera logo"
                              className="mw-240"
                              width={250}
                              height={75}
                              loading="lazy"
                            />
                          </div>
                        </Col>
                        <Col lg={4} md={6} className="col">
                          <div className="partners-logo text-lg-center">
                            <img
                              src={alphaGenLogo}
                              alt="AlphaGen logo"
                              className="mw-280"
                              width={250}
                              height={75}
                              loading="lazy"
                            />
                          </div>
                        </Col>
                        <Col lg={4} md={6} className="col">
                          <div className="partners-logo text-lg-right">
                            <img
                              src={eckertandzieglerLogo}
                              alt="EckertAndZiegler logo"
                              className="mw-250"
                              width={250}
                              height={75}
                              loading="lazy"
                            />
                          </div>
                        </Col>
                        <Col lg={4} md={6} className="col">
                          <div className="partners-logo text-lg-right">
                            <img
                              src={nucleusLogo}
                              alt="Nucleus Radiopharma logo"
                              className="mw-200"
                              width={195}
                              height={75}
                              loading="lazy"
                            />
                          </div>
                        </Col>
                        <Col lg={4} md={6} className="col">
                          <div className="partners-logo text-lg-left">
                            <img
                              src={pharmalogicLogo}
                              alt="PharmaLogic logo"
                              className="mw-280"
                              width={250}
                              height={75}
                              loading="lazy"
                            />
                          </div>
                        </Col>
                        <Col lg={4} md={6} className="col">
                          <div className="partners-logo text-lg-left">
                            <img
                              src={spectronrxLogo}
                              alt="Spectronrx logo"
                              className="mw-280"
                              width={250}
                              height={75}
                              loading="lazy"
                            />
                          </div>
                        </Col>
                      </Row>
                    </AccordionItemPanel>
                  </Col>
                  {/* <Col lg={2.5}>
                    <AccordionItemPanel>
                      <div className="partner-logo mb-16">
                        <strong>
                          Partner logos
                        </strong>
                      </div>
                      <div className="partners-logo mb-20">
                        <img src={dummyLogo} alt="Dummy logo" />
                      </div>
                      <div className="partners-logo mb-20">
                        <img src={dummyLogo} alt="Dummy logo" />
                      </div>
                      <div className="partners-logo">
                        <img src={dummyLogo} alt="Dummy logo" />
                      </div>
                    </AccordionItemPanel>
                  </Col> */}
                </Row>
              </AccordionItem>
            </Accordion>
          </div>
        </FluidContent>
      </div>
    </HcpLayout>
  );
};

export default OurApproachPage;

export const Head = () => (
  <Seo
    title="Our Approach"
    description="Discover more about the unique ARTBIO that harneses the power of ARTs and overcomes the challenges seen with radioligand therapies."
  />
);
