import React from 'react';
import "./innerBanner.scss";
import FluidContent from '../fluidContent';
import heroImgDesktop from "../../images/image-approach-hero-desktop.jpg";
import heroImgMobile from "../../images/image-approach-hero-mobile.jpg";
import ScrollTrigger from "gsap/ScrollTrigger";
import { gsap } from "gsap";

function InnerBanner() {
    const fadeRef = React.useRef([]);
    fadeRef.current = [];

    const fadeToRefs = (fade) => {
        if (fade) {
            fadeRef.current.push(fade);
        }
    };

    React.useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        fadeRef.current.forEach((fade) => {
            gsap.set(fade, {
                opacity: 0,
                y: 100,
            });

            gsap.to(fade, {
                opacity: 1,
                y: 0,
                duration: 1.5,
                scrollTrigger: {
                    trigger: fade,
                },
            });
        });
    }, []);
    return (
        <FluidContent className="inner-banner bg-cover" style={{}}>
            <img src={heroImgDesktop} alt="Patient Centric Approach" className='desktop-only' />
            <img src={heroImgMobile} alt="Patient Centric Approach" className='mobile-only' />
            <div className="centered-content">
                <div className="inner-banner__content" ref={fadeToRefs}>
                    <h1>A Patient <br /> Centric Approach</h1>
                </div>

            </div>

        </FluidContent>
    );
}

export default InnerBanner;